import data from "../lib/data";
import Axios from "axios";
import { updateConfig, getConfig, generateUUID } from "../lib/utils"
import queryString from "query-string";
import { rejects } from "assert";

export const authAxios = Axios.create({
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "-1" }
});

export const actions = {
  LOGGING_IN: "LOGGING_IN",
  LOGGED_IN: "LOGGED_IN",
  LOGIN_FAILED: "LOGIN_FAILED",

  GETTING_MAGIC_LINK: "GETTING_MAGIC_LINK",
  GOT_MAGIC_LINK: "GOT_MAGIC_LINK",
  FAILED_GET_MAGIC_LINK: "FAILED_GET_MAGIC_LINK",

  GETTING_COMPANY_LOGO: "GETTING_COMPANY_LOGO",
  GOT_COMPANY_LOGO: "GOT_COMPANY_LOGO",
  FAILED_GET_COMPANY_LOGO: "FAILED_GET_COMPANY_LOGO",

  GETTING_CUST_FROM_ID: "GETTING_CUST_FROM_ID",
  GOT_CUST_FROM_ID: "GOT_CUST_FROM_ID",
  FAILED_GET_CUST_FROM_ID: "FAILED_GET_CUST_FROM_ID",

  REGISTERING_NEW_EMAIL: "REGISTERING_NEW_EMAIL",
  REGISTERED_NEW_EMAIL: "REGISTERED_NEW_EMAIL",
  FAILED_REGISTER_NEW_EMAIL: "FAILED_REGISTER_NEW_EMAIL",

  SENDING_RESET_EMAIL: "SENDING_RESET_EMAIL",
  SENT_RESET_EMAIL: "SENT_RESET_EMAIL",
  FAILED_SEND_RESET_EMAIL: "FAILED_SENT_RESET_EMAIL"
};

function login(userName: string, password: string, rememberLogin: boolean) {

  let redirect = queryString.parse(window.location.search);

  let returnUrl = `/connect/authorize?client_id=appid&redirect_uri=${redirect.ReturnUrl?.includes("callback") ? getConfig().appEndpoint : redirect.ReturnUrl || getConfig().appEndpoint}&response_type=code+id_token&nonce=${generateUUID()}&scope=openid+profile+api+offline_access`;

  let formData = new FormData();
  formData.append('userName', userName);
  formData.append('password', password);
  formData.append('returnUrl', returnUrl);
  formData.append('rememberLogin', rememberLogin.toString());

  let body = {
    username: userName,
    password: password,
    returnUrl: returnUrl,
    rememberLogin: rememberLogin
  }

  let ok = new Promise(async (resolve, reject) => {

    authAxios.defaults.withCredentials = true;
    try {
      const response = await authAxios.post(`v1/user/login/authorize`, body);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });

  return ok;
}

export const loggedIn = (token: string) => (dispatch: any, getState: any) => {
  updateConfig({ AccessToken: token });
  return dispatch({ type: actions.LOGGING_IN, didLoginFail: false, token: token });
};

const loginUser = (userName: string, password: string, rememberLogin: boolean) => (dispatch: any, getState: any) => {
  dispatch({ type: actions.LOGGING_IN, didLoginFail: false });
  let promise = login(userName, password, rememberLogin)
  promise.then((response: any) => {
    let redirect = queryString.parse(window.location.search);
    let returnUrl = `/connect/authorize?client_id=appid&redirect_uri=${redirect.ReturnUrl?.includes("callback") ? getConfig().appEndpoint : redirect.ReturnUrl || getConfig().appEndpoint}&response_type=code+id_token&nonce=${generateUUID()}&scope=openid+profile+api+offline_access`;

    window.location = returnUrl as unknown as Location;

    dispatch(loggedIn(response.identityToken));
    return response.data;
  }).catch(response => {
    dispatch({ type: actions.LOGIN_FAILED, didLoginFail: true });
  });
};

const sendResetEmail = (emailAddr: string) => (dispatch: any, getState: any) => {
  dispatch({ type: actions.SENDING_RESET_EMAIL });
  const body = {
    email: emailAddr
  }
  let endpoint = `v1/user/reset`
  return data.post(endpoint, body, {
    headers: { "Content-Type": "application/json" }
  })
    .then((response: any) => {
      dispatch({ type: actions.SENT_RESET_EMAIL })
      return response.data;
    })
    .catch((error: Error) => {
      dispatch({ type: actions.FAILED_SEND_RESET_EMAIL })
      throw error;
    })
}

const getMagicLink = (emailAddr: string) => (dispatch: any) => {
  dispatch({ type: actions.GETTING_MAGIC_LINK });
  let endpoint = `v1/magiclink/get`;
  return data
    .post(endpoint, `"${emailAddr}"`, {
      headers: { "Content-Type": "application/json" },
    })

    .then((response: any) => {
      dispatch({ type: actions.GOT_MAGIC_LINK, magicLink: response.data });
      return response.data;
    })
    .catch((error: Error) => {
      //   handlePromiseError(error, "Error magic link", "Error magic link");
      dispatch({ type: actions.FAILED_GET_MAGIC_LINK });
      throw error;
    });
};

const getCompanySpecificMagicLink = (emailAddr: string, accountKey: any,  companyId: any) => (dispatch: any) => {
  dispatch({ type: actions.GETTING_MAGIC_LINK });
    let endpoint = `v1/magiclink/get/${accountKey}/${companyId}`;
  return data
    .post(endpoint, `"${emailAddr}"`, {
      headers: { "Content-Type": "application/json" },
    })

    .then((response: any) => {
      dispatch({ type: actions.GOT_MAGIC_LINK, magicLink: response.data });
      return response.data;
    })
    .catch((error: Error) => {
      dispatch({ type: actions.FAILED_GET_MAGIC_LINK });
      throw error;
    });
};

const getCompanyLogo = (accountKey: any, companyId: any) => (dispatch: any) => {
  dispatch({ type: actions.GETTING_COMPANY_LOGO });
    let endpoint = `v1/magiclink/getcompanylogo/${accountKey}/${companyId}`;
  return data
    .get(endpoint, {
      headers: { "Content-Type": "application/json" },
    })

    .then((response: any) => {
      dispatch({ type: actions.GOT_COMPANY_LOGO, companyLogo: response.data });
      return response.data;
    })
    .catch((error: Error) => {
      //   handlePromiseError(error, "Error magic link", "Error magic link");
      dispatch({ type: actions.FAILED_GET_COMPANY_LOGO });
      throw error;
    });
};

const getCustFromCustId = (custId: string, accountKey: any, companyId: string) => (dispatch: any) => {
  dispatch({ type: actions.GETTING_CUST_FROM_ID });
    let endpoint = `v1/magiclink/getcustomer/${custId}/${accountKey}/${companyId}`;
  return data
    .get(endpoint, {
      headers: { "Content-Type": "application/json" },
    })

    .then((response: any) => {
      dispatch({ type: actions.GOT_CUST_FROM_ID, customer: response.data });
      return response.data;
    })

    .catch((error: Error) => {
      //   handlePromiseError(error, "Error magic link", "Error magic link");
      dispatch({ type: actions.FAILED_GET_CUST_FROM_ID });
      throw error;
    });
}

const registerNewEmailAddress = (emailAddr: string, accountKey: any, companyId: string, custId: string) => (dispatch: any) => {
  dispatch({ type: actions.REGISTERING_NEW_EMAIL });
    let endpoint = `v1/magiclink/register/${custId}/${accountKey}/${companyId}`;
  return data
    .post(endpoint, `"${emailAddr}"`, {
      headers: { "Content-Type": "application/json" },
    })

    .then((response: any) => {
      dispatch({ type: actions.REGISTERED_NEW_EMAIL, magicLink: response.data });
      return response.data;
    })
    .catch((error: Error) => {
      //   handlePromiseError(error, "Error magic link", "Error magic link");
      dispatch({ type: actions.FAILED_REGISTER_NEW_EMAIL });
      throw error;
    });
};

export const dispatchToProps = (dispatch: any) => ({
  getMagicLink: (emailAddr: string) => {
    return dispatch(getMagicLink(emailAddr));
  },
    getCompanySpecificMagicLink: (emailAddr: string, accountKey: any, companyId: any) => {
        return dispatch(getCompanySpecificMagicLink(emailAddr, accountKey, companyId));
  },
    getCompanyLogo: (accountKey: any, companyId: any) => {
        return dispatch(getCompanyLogo(accountKey, companyId));
  },
    getCustFromCustId: (custId: string, accountKey: any, companyId: string) => {
        return dispatch(getCustFromCustId(custId, accountKey, companyId));
  },
    registerNewEmailAddress: (emailAddr: string, accountKey: any, companyId: string, custId: string) => {
        return dispatch(registerNewEmailAddress(emailAddr, accountKey, companyId, custId));
  },
  loggedIn: (token: string) => {
    dispatch(loggedIn(token));
  },
  loginUser: (userName: string, password: string, rememberLogin: boolean) => {
    return dispatch(loginUser(userName, password, rememberLogin));
  },
  sendResetEmail: (emailAddr: string) => {
    return dispatch(sendResetEmail(emailAddr));
  },
});
