import { getString, formatString } from "./strings";

class Resources {
  get Username() {
    return getString("USERNAME", "Username");
  }
  get Password() {
    return getString("PASSWORD", "Password");
  }

  get ForgotPassword() {
    return getString("FORGOT_PASSWORD", "Forgot password?");
  }

  get RememberMeFor30Days() {
    return getString("REMEMBER_ME_FOR_30_DAYS", "Remember me");
  }

  get Or() {
    return getString("OR", "Or");
  }

  get Language() {
    return getString("LANGUAGE", "Language");
  }

  get Edit() {
    return getString("EDIT", "Edit");
  }

  get Cancel() {
    return getString("CANCEL", "Cancel");
  }

  get Delete() {
    return getString("DELETE", "Delete");
  }

  get Hide() {
    return getString("HIDE", "Hide");
  }

  get Show() {
    return getString("SHOW", "Show");
  }

  get Submit() {
    return getString("SUBMIT", "Submit");
  }

  get SignIn() {
    return getString("SIGN_IN", "Sign in");
  }

  get CreateAccount() {
    return getString("CREATE_ACCOUNT", "Create an account");
  }

  get DontFeelLikeTypingYourPassword() {
    return getString("DONT_FEEL_LIKE_TYPING_YOUR_PASSWORD", "Don't feel like typing your password?");
  }

  get GetMagicLink() {
    return getString("GET_MAGIC_LINK", "Get a magic link");
  }

  get TiredOfRememberingPasswords() {
    return getString("TIRED_OF_REMEMBERING_PASSWORDS", "Tired of remembering passwords? So are we.");
  }

  get MagicLinkInstructions() {
    return getString(
      "MAGIC_LINK_INSTRUCTIONS",
      "Just enter your email below, if your email is recognized, we'll send you a link that will automatically log you in."
    );
  }

  get EmailAddress() {
    return getString("EMAIL_ADDRESS", "Email address");
  }

  get SendMeMagicLink() {
    return getString("SEND_ME_MAGIC_LINK", "Send me a magic link");
  }

  get MagicLinkSent() {
    return getString("MAGIC_LINK_SENT", "Magic link sent!");
  }

  get MagicLinkFailedSend() {
    return getString(
      "MAGIC_LINK_FAILED_SEND",
      "We are sorry that email address is invalid, please try another email address"
    );
  }

  get TryAgain() {
    return getString("TRY_AGAIN", "Try again");
  }

  get CheckEmailToGetLink() {
    return getString("CHECK_EMAIL_TO_GET_LINK", "Check your email to access the link.");
  }

  get LookingToViewInvoiceMakePayments() {
    return getString("LOOKING_TO_VIEW_INVOICE_AND_MAKE_PAYMENTS", "Looking to view invoices and make payments?");
  }

  get OopsWeDontRecognizeThatAddress() {
    return getString("OOPS_WE_DONT_RECOGNIZE_THAT_EMAIL", "Oops! We don't recognize that email address.");
  }

  get MagicLinkFailedCompanyMessage() {
    return getString(
      "MAGIC_LINK_FAILED_SEND_COMPANY",
      "Go back to retry a new email address or enter your customer ID below to register your email address. You can find this ID on any invoice we’ve sent you. "
    );
  }

  get Next() {
    return getString("NEXT", "Next");
  }

  get CustomerId() {
    return getString("CUSTOMER_ID", "Customer ID");
  }

  get RegisterEmailAddress() {
    return getString("REGISTER_EMAIL_ADDRESS", "Register email address");
  }

  get APEmailAddress() {
    return getString("AP_EMAIL_ADDRESS", "AP department email address");
  }

  get RegisterEmailInfo() {
    return getString(
      "REGISTER_EMAIL_INFO",
      "Now enter your AP department’s primary email address below. We will verify the address and then send you an email with a link to access your invoices and payments."
    );
  }

  GreatThanks(company) {
    return formatString(getString("GREAT_THANKS_COMPANY", "Great! Thanks {company}."), {
      company: company,
    });
  }

  get Success() {
    return getString("SUCCESS", "Success!");
  }

  WeAreVerifyingYourEmailReachOutToEmail(email) {
    return formatString(
      getString(
        "WE_ARE_VERIFYING_YOUR_EMAIL",
        "We are verifying your email address. Keep an eye out for an email with a magic link to access your invoices and payments. In the meantime, if you have questions contact {email}"
      ),
      {
        email: email,
      }
    );
}

 InvalidCustomerIdMessgae(arEmail) {
    return formatString(
        getString("OOPS_DONT_RECOGNIZE_CUST_ID_MESSAGE", "Retry a new Customer ID or send us an email at {arEmail} and we will help!"
        ),
        {
            arEmail: arEmail,
        }
    );
}


  get InvalidCustomerId() {
    return getString("OOPS_DONT_RECOGNIZE_CUST_ID", "Oops! We don't recognize this customer ID.");
  }

  get TheUserNameOrPasswordIsIncorrect() {
    return getString("THE_USER_NAME_PASSWORD_IS_INCORRECT", "Your username or password is incorrect.");
  }

  get FirstLetsFindYourAccount() {
    return getString("FIRST_LETS_FIND_YOUR_ACCOUNT", "First, let's find your account");
  }

  get FindAccount() {
    return getString("FIND_ACCOUNT", "Find account");
  }

  get ResendLink() {
    return getString("RESEND_LINK", "Resend link");
  }

  get TryAnotherEmail() {
    return getString("TRY_ANOTHER_EMAIL", "Try another email");
  }

  get ForgoutYourPassword() {
    return getString("FORGOT_YOUR_PASSWORD", "Forgot your password?");
  }

  get WellHelpYouResetAndGetBackOnTrack() {
    return getString("WELL_HELP_YOU_RESET_AND_GET_BACK_ON_TRACK", "We'll help you reset it and get back on track.");
  }

  get ResetPassword() {
    return getString("RESET_PASSWORD", "Reset password");
  }

  get PleaseEnterNewPassword() {
    return getString("PLEASE_ENTER_NEW_PASSWORD", "Please enter your new password below.");
  }

  get NewPassword() {
    return getString("NEW_PASSWORD", "New password");
  }

  get ConfirmPassword() {
    return getString("CONFIRM_PASSWORD", "Confirm password");
  }

  get SetNewPassword() {
    return getString("SET_NEW_PASSWORD", "Set new password");
  }

  get BackToLogin() {
    return getString("BACK_TO_LOGIN", "Back to login");
  }

  get IfYouHaveAccountWeWillSendLink() {
    return getString(
      "IF_YOU_HAVE_AN_ACCOUNT_WE_WILL_SEND_LINK",
      "If you have an account, we'll send you a password reset link."
    );
  }

  get CheckYourEmailForSecureLink() {
    return getString(
      "CHECK_YOUR_EMAIL_FOR_SECURE_LINK",
      "Check your email for a secure link to reset your password. If you don't see our email, check your spam folder."
    );
  }

  getString(name) {
    return getString(name.toUpperCase(), `[${name}]`);
  }
}

const resources = new Resources();

export default resources;
