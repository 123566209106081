import React, { Component } from "react";

class IconCheckCircle extends Component {
    render() {
        let { className, height } = this.props;

        return (
            <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                height={height}
                className={`icon ${className ? className : ""}`}
            >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
            </svg>
        );
    }
}

IconCheckCircle.defaultProps = {
    height: 22
};

export default IconCheckCircle;
