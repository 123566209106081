import * as React from 'react';
import Layout from './components/Layout';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import LoginView from './components/loginView';
import ResetPassword from "./components/resetPassword";
import MagicLinkRequest from "./components/magicLinkRequest";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

function App(props: any) {
    return (
        <Layout>
            <Switch>
                <Route path='/login'>
                    <LoginView flags={props.flags} />
                </Route>
                <Route path='/reset-password/:view'>
                    <ResetPassword />
                </Route>
                <Route path='/linkrequest/:accountKey/:companyId'>
                    <MagicLinkRequest />
                </Route>
                <Redirect to="/login" />
            </Switch>
        </Layout>
    )
}
export default withLDConsumer()(withRouter((App)));
