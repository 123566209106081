import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Card from "./library/card";
import CheckBox from "./library/checkbox";
import IconGlobe from "./library/icons/iconGlobe";
import IconLeftArrow from "./library/icons/iconLeftArrow";
import TextInput from "./library/textInput";
import DoneIcon from "./library/doneIcon";
import MainLoader from "./library/mainLoader";
import ErrorIcon from "./library/errorIcon";
import { dispatchToProps as loginDP } from "../store/login-actions";

import { withLDConsumer } from "launchdarkly-react-client-sdk";

import Resources from "../lib/resources";
import IconAlertCircle from "./library/icons/iconAlertCircle";
import { isEmpty } from "../lib/utils";
import LockstepLogo from "./library/lockstepLogo";
import IconCheckCircle from "./library/icons/iconCheckCircle";

type LoginState = {
  username: string,
  password: string,
  rememberMeChecked: boolean,
  magicLinkView: boolean,
  email: string,
  successView: boolean,
  failedView: boolean
}

type LoginProps = {
  match: any,
  history: any,
  location: any,
  getMagicLink: any,
  loginStore: any,
  loginUser: any,
  flags: any
}

const dispatchToProps = (dispatch: any) => ({
  ...loginDP(dispatch)
});

class LoginView extends Component<LoginProps, LoginState> {

  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      rememberMeChecked: false,
      magicLinkView: false,
      successView: false,
      failedView: false
    }
  }

  submitOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      console.log("here")
      this.submitLogin(e);
    }
  };

  submitLogin = (e: Event) => {
    e.preventDefault();
    this.props.loginUser(this.state.username, this.state.password, this.state.rememberMeChecked);
  };


  render() {
    return (
      <React.Fragment>
        <div className="login-page-container">
          <LockstepLogo />
          {this.props.loginStore.sentResetEmail ? <div className="password-reset-banner">
            <IconCheckCircle className="reset-banner-icon" height={21} />
            <span>{Resources.IfYouHaveAccountWeWillSendLink}</span>
          </div> : null}
          <Card className="login-page-card">
            {this.state.failedView ?
              <React.Fragment>
                <div className="success-view-container">
                  <div className="error-icon-container">
                    <ErrorIcon />
                  </div>
                  <p>{Resources.MagicLinkFailedSend}</p>
                  <button onClick={() => {
                    this.setState({ failedView: false, magicLinkView: true });
                  }} className="button-primary-square">{Resources.TryAgain}</button>
                </div>
              </React.Fragment> :
              this.state.successView ?
                <React.Fragment>
                  <div className="success-view-container">
                    <div className="done-icon-container">
                      <DoneIcon />
                    </div>
                    <p>{Resources.MagicLinkSent}</p>
                    <p>{Resources.CheckEmailToGetLink}</p>
                  </div>
                </React.Fragment>
                :
                this.state.magicLinkView ?
                  this.props.loginStore.gettingMagicLink ?
                    <div className="login-page-loading-container__small">
                      <MainLoader fullScreen={true} />
                    </div> :
                    <div className="magic-link-view-container">
                      <button className="back-button" onClick={() => this.setState({ magicLinkView: false })} ><IconLeftArrow height={22} /></button>
                      <p>{Resources.TiredOfRememberingPasswords}</p>
                      <p>{Resources.MagicLinkInstructions}</p>
                      <div className="magic-link-input-container">
                        <TextInput
                          width="23.2rem"
                          labelInline={false}
                          label={Resources.EmailAddress}
                          isValid={true}
                          textValue={this.state.email}
                          inputOnChange={(e: any) => {
                            let value = e.target.value;
                            this.setState({ email: value });
                            ;
                          }}
                        />
                      </div>
                      <div className="magic-link-input-container">
                        <button disabled={isEmpty(this.state.email)} onClick={() => {
                          this.props.getMagicLink(this.state.email).then((response: boolean) => {
                            this.setState({ successView: response, failedView: !response })
                          });
                        }} className="button-primary-square">{Resources.SendMeMagicLink}</button>
                      </div>
                    </div> :
                  this.props.loginStore.loggingIn ? <div className="login-page-loading-container__small">
                    <MainLoader fullScreen={true} />
                  </div> : <div
                    className="login-page-card-form"
                  >
                      {this.props.loginStore.loginFailed ? <div className="incorrect-password-message-container">
                        <IconAlertCircle height={16} />
                        <span>{Resources.TheUserNameOrPasswordIsIncorrect}</span>
                      </div> : null}
                      <div>
                        <TextInput
                          width="23.2rem"
                          labelInline={false}
                          label={Resources.Username}
                          isValid={true}
                          placeholder={Resources.Username}
                          textValue={this.state.username}
                          inputOnChange={(e: any) => {
                            let value = e.target.value;
                            this.setState({ username: value });
                            ;
                          }}
                        />
                      </div>
                      <div className="login-page-card-username-container">
                        <TextInput
                          width="23.2rem"
                          labelInline={false}
                          label={Resources.Password}
                          isValid={true}
                          isPassword
                          placeholder={Resources.Password}
                          textValue={this.state.password}
                          inputOnChange={(e: any) => {
                            let value = e.target.value;
                            this.setState({ password: value });
                            ;
                          }}
                        />
                      </div>
                      <Link to="/reset-password/request">{Resources.ForgotPassword}</Link>
                      <div className="login-page-card-form-checkbox-container">
                        <CheckBox label={Resources.RememberMeFor30Days} checked={this.state.rememberMeChecked} onChange={() => {
                          this.setState({ rememberMeChecked: !this.state.rememberMeChecked });
                        }} />

                      </div>
                      <div className="login-page-card-form-submit">
                        <button onClick={(e: any) => this.submitLogin(e)} className="button-primary-square">{Resources.SignIn}</button>
                      </div>
                      <div className="login-page-card-spacer">
                        <div className="spacer-div"></div>
                        <p>{Resources.Or}</p>
                        <div className="spacer-div"></div>
                      </div>
                      <div className="login-page-magic-link-container">
                        <p>{Resources.DontFeelLikeTypingYourPassword}</p>
                        <button onClick={() => this.setState({ magicLinkView: true })} className="button-primary-square">{Resources.GetMagicLink}</button>
                      </div>
                      {this.props.flags.authUiCreateAccountButton ?
                        <React.Fragment>
                          <div className="login-page-card-spacer">
                            <div className="spacer-div"></div>
                            <p>{Resources.Or}</p>
                            <div className="spacer-div"></div>
                          </div>
                          <div className="login-page-card-form-submit">
                            <button className="button-secondary-square">{Resources.CreateAccount}</button>
                          </div>
                        </React.Fragment>
                        : null}
                    </div>
            }
            <div className="language-button-container">
              <IconGlobe height={14} className="language-button-icon" />
              <span>{Resources.Language}</span>
            </div>
          </Card>
        </div >
      </React.Fragment >
    );
  }
}

const storeToProps = (store: any) => {
  return {
    loginStore: store.login,
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(LoginView)));