import React, { Component } from "react";
import Card from "./library/card";
import IconGlobe from "./library/icons/iconGlobe";
import TextInput from "./library/textInput";
import DoneIcon from "./library/doneIcon";
import MainLoader from "./library/mainLoader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IconLeftArrow from "./library/icons/iconLeftArrow";
import ErrorIcon from "./library/errorIcon";
import { isEmpty } from "../lib/utils"

import { dispatchToProps as loginDP } from "../store/login-actions";

import Resources from "../lib/resources";

type LoginState = {
    email: string,
    companyLogo: string,
    successView: boolean,
    failedView: boolean,
    customerId: string,
    enterEmailView: boolean,
    apEmailAddress: string,
    validCustId: boolean
}

type LoginProps = {
    match: any,
    history: any,
    location: any,
    getCompanySpecificMagicLink: any,
    getCompanyLogo: any,
    loginStore: any,
    getCustFromCustId: any,
    registerNewEmailAddress: any,
}

const dispatchToProps = (dispatch: any) => ({
    ...loginDP(dispatch)
});

class MagicLinkRequest extends Component<LoginProps, LoginState> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            companyLogo: "",
            customerId: "",
            successView: false,
            failedView: false,
            enterEmailView: false,
            apEmailAddress: "",
            validCustId: true
        }
    }

    componentDidMount() {
        this.props.getCompanyLogo(this.props.match.params.accountKey, this.props.match.params.companyId).then((response: string) => {
            this.setState({ companyLogo: response });
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.loginStore.gettingCompanyLogo ?
                    <div className="login-page-loading-container">
                        <MainLoader fullScreen={true} />
                    </div>
                    :
                    <div className="login-page-container">
                        <div className="login-page-logo">
                            {!isEmpty(this.state.companyLogo) ? <img src={this.state.companyLogo} alt="logo" />
                                : null}
                        </div>
                        <div className="profile-update-header-lockstep-box">
                            <span className="mr-1">connected by</span>
                            <svg viewBox="0 0 700.88 200.32" height="20" className="lockstep-logo ">
                                <path
                                    fill="#7d4ed8"
                                    d="M52.002526 28.708259l36.07 36.16 24.02-24.02 12.32 12.31-36.34 36.34-48.39-48.48z"
                                ></path>
                                <path
                                    fill="#ef3c6b"
                                    d="M51.892526 101.048259l-24.02-24.02 12.32-12.32 36.33 36.34-48.48 48.39-12.31-12.32z"
                                ></path>
                                <path
                                    fill="#1fb6c1"
                                    d="M63.252526 160.588259l-12.32-12.31 36.34-36.34 48.39 48.48-12.32 12.31-36.07-36.16z"
                                ></path>
                                <path
                                    fill="#f38923"
                                    d="M147.302526 51.998259l12.31 12.32-36.17 36.07 24.03 24.02-12.32 12.32-36.33-36.34z"
                                ></path>
                                <g className="lockstep-logo-text ">
                                    <path
                                        fill="currentColor"
                                        d="M203.712526 76.658259h17.63v37.68h24.79v15.07h-42.42zM255.632526 103.188259v-.15c0-15.3 12.58-27.5 28.86-27.5s28.71 12.06 28.71 27.35v.15c0 15.3-12.58 27.5-28.86 27.5s-28.71-12.06-28.71-27.35zm39.79 0v-.15c0-6.25-4.3-11.91-11.08-11.91-6.71 0-10.93 5.58-10.93 11.76v.15c0 6.25 4.29 11.91 11.08 11.91 6.71-.01 10.93-5.58 10.93-11.76zM323.982526 103.188259v-.15c0-15.75 12.28-27.5 28.03-27.5 11.6 0 19.44 5.65 23.89 13.71l-14.54 8.52c-1.96-3.77-4.75-6.4-9.57-6.4-5.95 0-9.87 5.2-9.87 11.53v.15c0 6.86 4.07 11.68 9.87 11.68 4.97 0 7.76-2.71 9.95-6.63l14.54 8.21c-4.45 7.84-11.83 14.24-24.94 14.24-14.62-.01-27.36-11.01-27.36-27.36zM388.722526 76.658259h17.63v19.74l15-19.74h20.5l-18.39 22.91 18.76 29.84h-21.03l-10.32-16.73-4.52 5.5v11.23h-17.63zM449.912526 121.498259l9.42-11.23c5.73 4.37 12.28 6.18 18.46 6.18 3.17 0 4.52-.83 4.52-2.26v-.15c0-1.51-1.66-2.34-7.31-3.47-11.83-2.41-22.23-5.8-22.23-16.95v-.15c0-10.02 7.84-17.78 22.38-17.78 10.17 0 17.71 2.41 23.81 7.23l-8.59 11.91c-4.97-3.62-10.93-5.2-15.82-5.2-2.64 0-3.84.9-3.84 2.19v.15c0 1.43 1.43 2.34 7.01 3.39 13.49 2.49 22.53 6.41 22.53 17.03v.15c0 11.08-9.12 17.86-23.28 17.86-10.71-.01-20.35-3.02-27.06-8.9zM526.102526 91.578259h-15.52v-14.92h48.68v14.92h-15.52v37.83h-17.63zM572.522526 76.658259h44.99v14.47h-27.66v5.35h25.77v12.74h-25.77v5.73h28.03v14.47h-45.36zM632.812526 76.658259h23.74c14.02 0 23.59 6.25 23.59 18.76v.15c0 12.74-9.72 19.52-23.96 19.52h-5.73v14.32h-17.63v-52.75zm22.38 25.85c4.52 0 7.54-2.03 7.54-5.8v-.15c0-3.84-2.79-5.8-7.46-5.8h-4.82v11.76z"
                                    ></path>
                                </g>
                            </svg>
                        </div>
                        <Card className="login-page-card">
                            {this.props.loginStore.gettingCustFromId || this.props.loginStore.registeringEmail || this.props.loginStore.gettingMagicLink ?
                                <div className="login-page-loading-container__small">
                                    <MainLoader fullScreen={true} />
                                </div> :
                                this.state.enterEmailView ?
                                    <React.Fragment>
                                        <div className="success-view-container">
                                            <button className="back-button" onClick={() => this.setState({ enterEmailView: false, failedView: true })} ><IconLeftArrow height={22} /></button>
                                            <div className="text-container">
                                                <p className="failed-message-text">{Resources.GreatThanks(this.props.loginStore.customer.custName)}</p>
                                                <p className="failed-message-text">{Resources.RegisterEmailInfo}</p>
                                            </div>
                                            <div className="magic-link-input-container">
                                                <TextInput
                                                    width="23.2rem"
                                                    labelInline={false}
                                                    label={Resources.APEmailAddress}
                                                    isValid={true}
                                                    textValue={this.state.apEmailAddress}
                                                    inputOnChange={(e: any) => {
                                                        let value = e.target.value;
                                                        this.setState({ apEmailAddress: value });
                                                        ;
                                                    }}
                                                />
                                            </div>
                                            <button disabled={isEmpty(this.state.apEmailAddress)} onClick={async () => {
                                                await this.props.registerNewEmailAddress(this.state.apEmailAddress, this.props.match.params.accountKey, this.props.match.params.companyId, this.state.customerId);
                                                this.setState({ successView: true, enterEmailView: false });
                                            }} className="button-primary-square">{Resources.RegisterEmailAddress}</button>
                                        </div>
                                    </React.Fragment> :
                                    this.state.failedView ?
                                        <React.Fragment>
                                            <div className="success-view-container">
                                                <button className="back-button" onClick={() => this.setState({ failedView: false, validCustId: true })} ><IconLeftArrow height={22} /></button>

                                                <div className="error-icon-container">
                                                    <ErrorIcon />
                                                </div>
                                                <p className="failed-message-text">{this.state.validCustId ? Resources.OopsWeDontRecognizeThatAddress : Resources.InvalidCustomerId}</p>
                                                <p className="failed-message-text">{this.state.validCustId ? Resources.MagicLinkFailedCompanyMessage : Resources.InvalidCustomerIdMessgae(this.props.loginStore.customer.collectorEmail)}</p>
                                                <div className="magic-link-input-container">
                                                    <TextInput
                                                        width="23.2rem"
                                                        labelInline={false}
                                                        label={Resources.CustomerId}
                                                        isValid={true}
                                                        textValue={this.state.customerId}
                                                        invalidText={Resources.InvalidCustomerId}
                                                        inputOnChange={(e: any) => {
                                                            let value = e.target.value;
                                                            this.setState({ customerId: value, validCustId: true });
                                                            ;
                                                        }}
                                                    />
                                                </div>
                                                <button onClick={async () => {
                                                    await this.props.getCustFromCustId(this.state.customerId, this.props.match.params.accountKey, this.props.match.params.companyId);
                                                    if (isEmpty(this.props.loginStore.customer.custName)) {
                                                        this.setState({ validCustId: false })
                                                    }
                                                    else {
                                                        this.setState({ failedView: false, enterEmailView: true, validCustId: true });
                                                    }
                                                }} className="button-primary-square" disabled={isEmpty(this.state.customerId)}>{Resources.Next}</button>
                                            </div>
                                        </React.Fragment> :
                                        this.state.successView ?
                                            <React.Fragment>
                                                <div className="success-view-container">
                                                    <div className="done-icon-container">
                                                        <DoneIcon />
                                                    </div>
                                                    {this.state.apEmailAddress != "" ?
                                                        <React.Fragment>
                                                            <p>{Resources.Success}</p>
                                                            <p>{Resources.WeAreVerifyingYourEmailReachOutToEmail(this.props.loginStore.customer.collectorEmail)}</p>
                                                        </React.Fragment> :
                                                        <React.Fragment>
                                                            <p>{Resources.MagicLinkSent}</p>
                                                            <p>{Resources.CheckEmailToGetLink}</p>
                                                        </React.Fragment>
                                                    }

                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className="magic-link-view-container">
                                                    <p>{Resources.LookingToViewInvoiceMakePayments}</p>
                                                    <p>{Resources.MagicLinkInstructions}</p>
                                                    <div className="magic-link-input-container">
                                                        <TextInput
                                                            width="23.2rem"
                                                            labelInline={false}
                                                            label={Resources.EmailAddress}
                                                            isValid={true}
                                                            textValue={this.state.email}
                                                            inputOnChange={(e: any) => {
                                                                let value = e.target.value;
                                                                this.setState({ email: value });
                                                                ;
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="magic-link-input-container">
                                                        <button disabled={isEmpty(this.state.email)} onClick={() => {
                                                            this.props.getCompanySpecificMagicLink(this.state.email, this.props.match.params.accountKey, this.props.match.params.companyId).then((response: boolean) => {
                                                                this.setState({ successView: response, failedView: !response, validCustId: true })
                                                            });;
                                                        }} className="button-primary-square">{Resources.SendMeMagicLink}</button>
                                                    </div>
                                                </div>
                                            </React.Fragment>}
                            <div className="language-button-container">
                                <IconGlobe height={14} className="language-button-icon" />
                                <span>{Resources.Language}</span>
                            </div>
                        </Card>
                    </div>
                }
            </React.Fragment>

        );
    }
}

const storeToProps = (store: any) => {
    return {
        loginStore: store.login,
    };
};

export default withRouter(connect(storeToProps, dispatchToProps)(MagicLinkRequest));
